.map-container {
    height: 400px;
}

.surfIcon {
    /* color: red;
    font-size: 100px;
    border: 1px solid #333;
    width: 200px;
    height: 200px; */
}
