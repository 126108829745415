.mapboxgl-popup-close-button {
    color: red !important;
    font-size: 35px;
}

.mapboxgl-popup-content {
    background: rgba(51, 51, 51, 0.7) !important;
    color: white;
    /* stroke: white; */
    /* fill: white; */
}
